<template>
  <div class="toupWrap">
    <div class="topNavBox">
      <div class="topbox clearfix border">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">充值</p>
      </div>
    </div>
    <div class="consumeList clearfix">
      <a
        href="javaScript:"
        v-for="(item, index) in payList"
        :key="item.money"
        class="fl"
        :class="chooseIndex == index?'active':''"
        @click="chooseActive(item.money, index, item.productId)"
      >
        <p>
          <span class="font">￥</span><span class="num">{{ item.money }}</span>
        </p>
        <p class="yuanbi">{{ item.yuanBi }}</p>
      </a>
    </div>
    <ul class="centenList">
      <li class="clearfix" @click="chooseType(1)">
        <a class="clearfix" href="javaScript:">
          <img class="fl item" src="~img/account/PayPal.png" alt="" />
          <p class="fl"></p>

          <div :class="{ active: type == 1, choose: true, fr: true }">
            <img src="~img/account/Shape@2x.png" alt="" />
          </div>
        </a>
      </li>
    </ul>
    <div id="paypal-button-container" style="display:flex;justify-content:center;margin-top: 100px"></div>
<!--    <button id="" class="payBtn" @click="pay">立即充值：{{ allNum }}元</button>-->
    <div class="payhint" style="margin-top: 30px">
      <p>
        {{payTip}}
      </p>
    </div>
<!--    <div-->
<!--      class="isPaySuccess"-->
<!--      v-if="-->
<!--        isShow == 'true' && ($route.query.topUpId || $route.query.out_trade_no)-->
<!--      "-->
<!--    >-->
<!--      <div class="successhint">-->
<!--        <div class="bg">-->
<!--          <div>提示</div>-->
<!--        </div>-->
<!--        <p class="hint">請確認微信支付是否已完成</p>-->
<!--        <div @click="payOk" class="success button">已完成支付</div>-->
<!--        <div @click="payError" class="error button">支付遇到問題，重新支付</div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '818816462586527');
  fbq('track', 'Purchase', {value: 1.00, currency: 'USD'});

import { payMoneyList, payMoney, zfMoney, payisSuccess } from "apiurl/pay";
import baseURL from "@/api/env";
import { setToken, getToken } from "utils/storage";
// import '../../../../public/paypal'
import {Toast} from "mint-ui";
// import PayPal from 'vue-paypal-checkout'
export default {
  data() {
    return {
      payList: [],
      payTip: '',
      chooseIndex: 1,
      chooseMoney: 30,
      type: 1,
      allNum: 0,
      allNumId: '',
      orderNo: "",
      isShow: localStorage.getItem("payShow") || "true",
    };
  },
  // components: {
  //   PayPal
  // },
  created() {
    if (this.$route.query.redirect) {
      localStorage.setItem("backurl", this.$route.query.redirect);
    }
    this.$nextTick(() => {
      this.paypalFun()
      this.getGrage();
    });
  },
  mounted() {

  },
  methods: {
    // 獲取列表
    getGrage() {
      payMoneyList().then(res => {
        if (res.success) {
          this.payList = res.data.product.gradeList;
          this.payList.forEach((item, index) => {
            if (item.defaultSelect == true) {
              this.allNum = item.money
              this.allNumId = item.productId
              this.chooseIndex = index
            }
          })
          this.payTip = res.data.comment
        }
      });
    },
    paypalFun () {
      let _this = this
      paypal.Buttons({
        style: {
          label: 'paypal',
          size: 'small',
          color: 'blue',
          shape: 'rect',
          layout: 'horizontal',
        },
        // 請求你的服務器下單
        // 注意此處和JS SDK文檔處寫的由actions.order.create下單不同
        // 這裡請求你服務端的接口，由你服務器下單並返回一個PayPal的訂單ID
        createOrder: function(data, actions) {
          return fetch(baseURL.apiURL + '/consume/core/pay/order/paypal?productId='+_this.allNumId, {
            method: 'get',
            headers:{
              'Authorization': "Bearer " + getToken(),
              'client-platform': '2',
              'lang': "zh_TW",
              'app-name': "haiwen",
              'udid': "11223122221",
              'app-version': "1.0",
              'timestamp':　new Date().getTime()
            }
          }).then(function(res) {
            return res.json();
          }).then(function(orderData) {
            if (!orderData.success) {
              _this.Toast({
                message: orderData.message,
                duration: 2000,
                position: "center"
              });
            }
            return orderData.data.payPalId;
          });
        },
        // 告知你的服務器付款結果
        // 你的服務器通過PayPal返回訂單ID,請求Capture payment for order確認訂單
        // 如果PayPal返回INSTRUMENT_DECLINED錯誤，前端可以通過actions.restart()重啓付款流程
        onApprove: function(data, actions) {
          return fetch(baseURL.apiURL + '/consume/core/pay/verify/paypal?orderId='+data.orderID, {
            method: 'get',
            headers:{
              'Authorization': "Bearer " + getToken()
            }
          }).then(function(res) {
            return res.json();
          }).then(function(orderData) {
            console.log(orderData)
            if (orderData.success) {
              _this.Toast({
                message: "充值成功~",
                duration: 2000,
                position: "center"
              });
            } else {
              _this.Toast({
                message: orderData.message,
                duration: 2000,
                position: "center"
              });
            }
            // var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
            // if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
            //   return actions.restart(); // 重啓付款流程,如果你需要的話
            // }

            // 繼續處理付款完成邏輯
            // ...
            // localStorage.setItem("payShow", false);
            // if (localStorage.getItem("backurl")) {
            //   _this.$router.push(localStorage.getItem("backurl"));
            //   localStorage.removeItem("backurl");
            // } else {
            //   _this.$router.push("/center?back=home");
            // }
          });
        }

      }).render('#paypal-button-container');
    },
    seeBack() {
      if (localStorage.getItem("backurl")) {
        this.$router.push(localStorage.getItem("backurl"));
        localStorage.removeItem("backurl");
      } else {
        this.$router.push("/center?back=home");
      }
    },
    payError() {
      this.isShow = false;
      localStorage.setItem("payShow", false);
    },
    payOk() {
      payisSuccess({
        topUpId: this.$route.query.topUpId || this.$route.query.out_trade_no
      }).then(res => {
        if (res.success) {
          this.isShow = false;
          localStorage.setItem("payShow", false);
          if (localStorage.getItem("backurl")) {
            this.$router.push(localStorage.getItem("backurl"));
            localStorage.removeItem("backurl");
          } else {
            this.$router.push("/center?back=home");
          }
        } else {
          this.isShow = false;
          localStorage.setItem("payShow", false);
        }
      });
    },
    chooseType(type) {
      this.type = type;
    },
    // 選擇錢數
    chooseActive(money, index, productId) {
      this.chooseIndex = index;
      this.chooseMoney = money;
      this.allNum = money;
      this.allNumId = productId
    },
    // 充值
    pay() {
      if (this.type == 1) {
        payMoney({ amount: this.allNum * 100 }).then(res => {
          localStorage.setItem("payShow", true);
          this.orderNo = res.data.orderNo;
          window.location.href = `${res.data.payUrl}`;
        });
      } else {
        zfMoney({ amount: this.allNum * 100 }).then(res => {
          if (res.success) {
            let form = res.data.orderInfo;
            this.orderNo = res.data.topUpId;
            this.$router.push({
              path: "/alipay",
              query: { htmls: form }
            });
          }
        });
      }
    },
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.toupWrap
    width 100vw
    height 100vh
    background:rgba(249,249,249,1);
.consumeList
    margin 27px 24px 0
    a
        width:336px;
        height:128px;
        display block
        background:rgba(255,255,255,1);
        border-radius:5px;
        border:1px solid rgba(218,218,218,1);
        box-sizing border-box
        padding 18px 0 0 17px
        margin-bottom 28px
        &.active
            background:rgba(203,227,255,0.18);
            border none
            box-shadow:0px 0px 10px 0px rgba(0,131,254,1);
        &:nth-child(2n)
            margin-left 28px
        .font
            font-size:30px;
            font-weight:600;
        .num
            font-size 42px
            font-family:Impact;
        .yuanbi
            margin-top 3px
            font-size:20px;
            color:rgba(85,85,85,1);
.account-input
    margin 0 26px 19px
    .myAccount
        display block
        width calc(100% - 25px)
        height 70px
        padding-left 25px
        placeMain(#999999, 24px, 0px)
.centenList
    padding 10px 23px 0 43px
    background #fff
    border:1px solid rgba(238,238,238,1);
    li
        padding 27px 0
        &:nth-child(1)
            // border-bottom 2px solid #eee
        .item
            width 155px
            height 38px
        a
            img
                width 14px
                &.fr
                    margin-top 7px
        button
            width 87px
            height 45px
            background rgba(92, 162, 245, 1)
            border-radius 23px
            color #fff
        p
            color #333333
            line-height 42px
            font-size 30px
            margin-left 21px
            span
                color #999999
                display inline-block
                margin-left 5px
                font-size 24px
        .choose
            width:28px;
            height:28px;
            background:rgba(255,255,255,1);
            border:2px solid rgba(151,151,151,1);
            border-radius 50%
            text-align center
            line-height 28px
            &.active
                width:32px;
                height:32px;
                background:linear-gradient(180deg,rgba(126,223,255,1) 0%,rgba(73,202,255,1) 100%);
                box-shadow:0px 1px 3px 0px rgba(108,216,255,1);
                border none
                line-height 32px
.payBtn
    width:465px;
    height:70px;
    background:linear-gradient(135deg,rgba(122,209,255,1) 0%,rgba(29,133,241,1) 100%);
    box-shadow:0px 1px 20px 0px rgba(118,206,254,1);
    border-radius:35px;
    margin 36px auto 36px
    display block
    font-size:32px;
    color #fff
.payhint
    padding 0px 22px 11px 28px
    font-size:22px;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height 40px
    white-space pre-wrap
.isPaySuccess
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.48);
    position fixed
    top 0
    left 0
    .successhint
        width:550px;
        height:364px;
        background:rgba(255,255,255,1);
        box-shadow:0px 2px 8px 0px rgba(0,0,0,0.24);
        border-radius:22px;
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        .bg
            width:550px;
            height:99px;
            text-align center
            padding-top 18px
            font-size:36px;
            font-weight:500;
            color:rgba(85,85,85,1);
            border-radius:22px 22px 0 0;
            background url("../../../static/account/tankuangbg@2x.png") 0 0
            background-size cover
        .hint
            font-size:28px;
            color #555555
            text-align center
            line-height 44px
        .button
            width 100%
            background #fff
            color #555
            padding 25px 0
            text-align center
            font-size 30px
            &.success
                border-bottom 1px solid #999
                color #2189F2
                margin-top 15px
            &.error
                border-radius:22px;
</style>
